<template>
  <div class="content">
    <a-affix :offset-top="0">
      <Header title="订单详情" />
    </a-affix>

    <div class="part-content">
      <a-spin tip="Loading..." :spinning="loading">
        <div class="part">
          <EInfo title="房间" :col="7" :value="order.tableName" />
          <EInfo title="就餐时间" :col="7" :value="order.bookTime" />
          <EInfo title="餐标" :col="7" :value="order.comboPrice" />
          <EInfo title="人数" :col="7" :value="order.peopleCount" />
          <EInfo title="姓名" :col="7" :value="order.customerName" />
          <EInfo title="手机号码" :col="7" :value="order.customerMobile" />
          <EInfo
            title="备注"
            :col="7"
            v-if="order.customerRemark"
            :value="order.customerRemark"
          />
        </div>
        <div class="part">
          <EInfo
            title="套餐"
            :col="7"
            :valueCol="12"
            :value="order.comboName"
          />
          <div v-for="(v, k) in orderItems" :key="k" class="dish-item">
            <span>
              <a-tag color="green" v-if="v.state === 0">加菜</a-tag>
              <a-tag color="red" v-if="v.state === 1">赠菜</a-tag>
              {{ v.dish.name }}
            </span>

            <span class="dish-price"
              >¥{{ v.sellingPrice }} x{{ v.quantity }}</span
            >
          </div>

          <a-button class="add-dish-btn" @click="onAddDish">赠菜</a-button>
        </div>

        <div class="part">
          <div class="discount">
            <EInfo title="当前折扣" :col="7" :value="order.disCount || 10" />
            <a-button class="dis-btn" @click="setDiscount">设置折扣</a-button>
          </div>
          <EInfo title="订单金额" :col="7" :value="'¥' + order.orderAmount" />
          <EInfo title="支付金额" :col="7" :value="'¥' + order.payAmount" />

          <div :class="'status stu-' + order.orderStatus">
            <span v-if="order.orderStatus <= 3">已预订</span>
            <span v-if="order.orderStatus === 4">已开餐</span>
            <span v-if="order.orderStatus === 5">已退订</span>
            <span v-if="order.orderStatus === 6">未到</span>
            <span v-if="order.orderStatus === 7">已结算</span>
          </div>
        </div>
      </a-spin>
    </div>

    <div class="add-dish"></div>

    <van-popup v-model:show="show" position="bottom">
      <van-picker
        title="选择折扣"
        :columns="columns"
        :default-index="defaultIndex"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Header from './Header'
import { OrderClass } from '@/apis/order'
import { ConfigClass } from '@/apis/config'
import defaultConfig from '@/config/default.config'
import EInfo from '@/components/customer/form/Info'
import { Popup, Picker, Notify } from 'vant'
import 'vant/es/popup/style'
import 'vant/es/picker/style'
import 'vant/es/notify/style'
import storage from 'store'

const api = new OrderClass()
const config = new ConfigClass()
export default defineComponent({
  components: {
    Header,
    EInfo,
    [Popup.name]: Popup,
    [Picker.name]: Picker
  },
  setup () {
    const order = ref({})
    const store = ref({})
    const molingMax = ref()
    const zhekouMin = ref()
    const discount = ref(10)

    const loading = ref(false)

    const id = ref('')

    const show = ref(false)
    const columns = ref([])
    const orderItems = ref([])

    const onGetOrderDetail = (id) => {
      loading.value = true
      api.detail(id).then((resp) => {
        order.value = resp

        // 加菜或赠菜
        orderItems.value = resp.orderItems.filter((x) => x.type === 4)

        discount.value = resp.disCount

        loading.value = false
      })
    }

    const onGetConfig = () => {
      config.detail('Moling').then((resp) => {
        molingMax.value = Number(resp.value)
      })

      config.detail('Discount').then((resp) => {
        const zhekou = Number(resp.value)
        const zk = []
        for (let i = 10; i >= zhekou; i -= 0.1) {
          const z = Number(i.toFixed(1))
          zk.push(z)
        }

        columns.value = zk
      })
    }

    const onConfirm = (e) => {
      api.zhekou(id.value, e).then(() => {
        onGetOrderDetail(id.value)
        Notify({ type: 'success', message: '设置成功' })
        onCancel()
      })
    }

    const onCancel = () => {
      show.value = false
    }

    const setDiscount = () => {
      show.value = true
    }

    onGetConfig()
    return {
      order,
      store,
      discount,
      molingMax,
      zhekouMin,
      show,
      columns,
      id,
      orderItems,
      loading,
      onGetOrderDetail,
      onConfirm,
      onCancel,
      setDiscount
    }
  },

  created () {
    const id = this.$route.params.id
    this.id = id
    this.onGetOrderDetail(id)

    this.getStoreInfo()
  },

  computed: {
    defaultIndex () {
      const dis = this.discount
      if (dis === 0) return 0

      const list = this.columns
      return list.indexOf(dis)
    }
  },

  methods: {
    async getStoreInfo () {
      const store = this.$store.state.user.storeInfo.id
        ? this.$store.state.user.storeInfo
        : await this.$store.dispatch('GetStoreInfo', defaultConfig.store_id)

      this.store = store
    },

    onAddDish () {
      storage.set('DISH_ORDER_ID', this.id)
      this.$router.push({ name: 'shareholder-dish-list' })
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #f1f1f1;
  .part-content {
    padding: 0.2rem;
    .part {
      box-shadow: 0 0 0.1rem rgba(153, 153, 153, 0.3);
      margin-bottom: 0.3rem;
      padding: 0.3rem 0.2rem;
      position: relative;
      background: #fff;
      border-radius: 5px;

      h1 {
        font-size: 0.4rem;
        line-height: 0.8rem;
        margin: 0;
      }
      .address {
        font-size: 0.2rem;
      }
      .actions {
        position: absolute;
        top: 0.3rem;
        right: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.8rem;
        .action {
          border: 1px solid #ceb175;
          border-radius: 50%;
          width: 0.5rem;
          height: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ceb175;
          font-size: 0.3rem;
          margin-left: 0.2rem;
        }
      }
      .status {
        position: absolute;
        bottom: 0.2rem;
        right: 0.2rem;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid #000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-20deg);
        padding: 0.1rem;
        span {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.3rem;
          color: #333;
          font-weight: 800;
          border: 1px solid #000;
          border-radius: 50%;
        }
        &.stu-0,
        &.stu-1,
        &.stu-2,
        &.stu-3 {
          border-color: #fcd63d;
          span {
            border-color: #fcd63d;
            color: #fcd63d;
          }
        }
        &.stu-4 {
          border-color: #ff4f4f;
          span {
            border-color: #ff4f4f;
            color: #ff4f4f;
          }
        }
        &.stu-5 {
          border-color: #f50;
          span {
            border-color: #f50;
            color: #f50;
          }
        }
        &.stu-6 {
          border-color: #ccc;
          span {
            border-color: #ccc;
            color: #ccc;
          }
        }
        &.stu-7 {
          border-color: #87d068;
          span {
            border-color: #87d068;
            color: #87d068;
          }
        }
      }
    }
  }
}

.discount {
  border-bottom: 1px solid #ccc;
  position: relative;
  .dis-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.add-dish-btn {
  position: absolute;
  top: 0.3rem;
  right: 0.2rem;
}

.dish-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.3rem;
}
</style>
